/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
//@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
/* @import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css"; */

/* Optional CSS utils that can be commented out */
/* @import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css"; */
@import "@ionic/angular/css/flex-utils.css";

//Imports for Component Library
@import "@uag/fonts";
@import "material-symbols";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@doka/common/styles/global.scss";
@import "./theme/styles.scss";

ion-content::part(scroll) {
  overflow-x: auto;
}

.hidden {
  display: none !important;
}

.menu {
  background: var(--color-background-menu);
  height: 100%;
  display: flex;
  flex-flow: column;
  width: 250px;
  transition: 0.5s;

  .menuItem {
    cursor: pointer;
    background: var(--color-background-menu);
    display: flex;
    flex-flow: row nowrap;
    padding-top: var(--spacing-base);
    padding-bottom: var(--spacing-base);
    padding-right: var(--spacing-lg);
    padding-left: var(--spacing-lg);
    color: #ffffff;

    &:hover {
      background: #444444;
    }

    p {
      text-wrap: wrap;
      opacity: 1;
      transition: 0.5s;
    }

    doka-icon {
      margin-right: 16px;
    }
  }
}

.menuIsClosed {
  width: 50px;
  .menuItem {
    p {
      opacity: 0;
    }
  }
}

.userMenu {
  background: var(--color-background-menu);
  display: flex;
  flex-flow: column;

  .menuItem {
    cursor: pointer;
    background: var(--color-background-menu);
    display: flex;
    flex-flow: row nowrap;
    padding-top: var(--spacing-base);
    padding-bottom: var(--spacing-base);
    padding-right: var(--spacing-lg);
    padding-left: var(--spacing-lg);
    color: #ffffff;

    &:hover {
      background: #444444;
    }

    p {
      text-wrap: wrap;
    }

    doka-icon {
      margin-right: 16px;
    }
  }
}

.report-container {
  height: calc(100vh - 68px);
}
