ion-header {
  ion-toolbar {
    --background: var(--color-background-highlight);
    height: 68px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  ion-title {
    --color: var(--color-text-accent);
  }
}

.content-wrapper {
  display: flex;
  flex-flow: column nowrap;
  padding: var(--spacing-base);
}
